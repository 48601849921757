<template>
    <div>      
        <div v-if="!loadedCalculation" id="app" class="notCollapsable">
            <pc-menu></pc-menu>
            <p id="content">Lade Daten...</p>
            <pc-summary></pc-summary>   
        </div>
        <div v-else id="app" class="notCollapsable">
            <pc-menu></pc-menu>

            <router-view id="content"/>

            <pc-summary></pc-summary>    

        </div>
    </div>
</template>

<script>
export default {
    name: "Calculation",
    components: {
    },
    data() {
        return {
        
        }
    },
    created() {
        if (!document.cookie.match(/calculation_loaded=\d+/)[0].replace("calculation_loaded=", "")) {
            this.$store.router.push("/")
        }
    },
    computed: {
        loadedCalculation() {
            return this.$store.state.loadedCalculation
        },
        loaded() {
            // console.log(this.$store.state.data);
            if(!this.$store) return 0
            return !!this.$store.state.data
        }
    },
    methods: {
    }
}
</script>

<style>

</style>
